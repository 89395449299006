// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
// Transloco
import { Translation, TranslocoLoader, translocoConfig, TranslocoModule, provideTransloco } from '@jsverse/transloco';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
// Environment
import { environment } from '../environments/environment';
// Constants
import { APP_CONSTANT } from './constants/app-constants';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

  /**
   * Creates an instance of TranslocoHttpLoader.
   * @param {HttpClient} http
   * @memberof TranslocoHttpLoader
   */
  constructor(private http: HttpClient) { }

  /**
   * Get translation from server
   * @param {string} lang
   * @return {*} 
   * @memberof TranslocoHttpLoader
   */
  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  imports: [
  ],
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['da-dk', 'de-de', 'es-es', 'fi-fi', 'fr-fr', 'is-is', 'it-it', 'nb-no', 'en-se', 'sv-se'],
        defaultLang: 'en-se',
        fallbackLang: 'en-se',
        reRenderOnLangChange: false,
        prodMode: environment.production
      }),
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage
      },
      storageKey: APP_CONSTANT.USER_LANGUAGE_KEY
    })
  ]
})
export class TranslocoRootModule { }
